import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";

function Map() {
  return (
    <div className="m-3">
      <iframe
        title="embedded-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.942346601232!2d5.832523615746051!3d51.109523879571704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0c982f305702d%3A0x8e4d39350a94a11c!2sTransportbedrijf%20Geert%20Damen%20V.O.F.!5e0!3m2!1sen!2snl!4v1663771853759!5m2!1sen!2snl"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
