import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";

import logo from "../logo.png";

function Info() {
  return (
    <div className="m-3 center">
      <div>
        <img src={logo} width="300" alt="logo" />
        <p>Transportbedrijf Geert Damen VOF</p>
      </div>
      <div>
        <a href="mailto:info@geertdamen.nl">info@geertdamen.nl</a>
        <br />
        <a href="tel:+31653534437">+316-53534437</a>
        <br />
        Daalderweg 4
        <br />
        6109 AX Ohé en Laak
      </div>
    </div>
  );
}

export default Info;
