import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Button, Container, Form } from "react-bootstrap";

function Contact() {
  return (
    <Container className="d-flex justify-content-center">
      <div className="w-100" style={{ maxWidth: "650px" }}>
        <h3>Contact</h3>
        <Form action="https://formspree.io/f/xzbwenkd" method="POST">
          <Form.Group id="name" className="mb-3">
            <Form.Label>Naam*</Form.Label>
            <Form.Control type="text" name="naam" required />
          </Form.Group>
          <Form.Group id="company" className="mb-3">
            <Form.Label>Bedrijf*</Form.Label>
            <Form.Control type="text" name="bedrijf" required />
          </Form.Group>
          <Form.Group id="email" className="mb-3">
            <Form.Label>Email*</Form.Label>
            <Form.Control type="email" name="email" required />
          </Form.Group>
          <Form.Group id="phone" className="mb-3">
            <Form.Label>Telefoonnummer*</Form.Label>
            <Form.Control type="text" name="telefoon" required />
          </Form.Group>
          <Form.Group id="message" className="mb-3">
            <Form.Label>Bericht*</Form.Label>
            <Form.Control as="textarea" rows={3} name="bericht" required />
          </Form.Group>
          <Button type="submit" className="w-100">
            Verstuur
          </Button>
        </Form>
      </div>
    </Container>
  );
}

export default Contact;
