import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Info from "./components/info.component";
import Map from "./components/map.component";
import Contact from "./components/contact.component";

function App() {
  return (
    <div className="header">
      <Info />
      <Map />
      <Contact />
    </div>
  );
}

export default App;
